
import khalilImage from '../../../../images/khalil-headshot.png'

const authorConstants = {
  khalil: {
    name: 'Khalil Stemmler',
    about: 'https://twitter.com/khalilstemmler',
    image: khalilImage,
    jobTitle: `Software Essentialist ⚡`,
    description: `I'm Khalil. I turn code-first developers into confident crafters without having to buy, read & digest hundreds of complex programming books. Using Software Essentialism, my philosophy of software design, I coach developers through boredom, impostor syndrome, and a lack of direction to master software design and architecture. Mastery though, is not the end goal. It is merely a step towards your Inward Pull.`,
    resourceLandingPageDescription: `Khalil is a software developer, writer, musician, and ex-failed-startup co-founder of Univjobs. 
    He has 6+ years of experience 
writing software with Java, JavaScript, Node.js, and as of late: TypeScript. He writes about software design and architecture best practices with 
TypeScript and is especially passionate about Domain-Driven Design and GraphQL.`
  }
}

export default authorConstants;

// I teach developers the philosophy of Software Essentialism - a way to master the art of software design and architecture, tackle difficult problems and. the 8 essential things developers struggling with impostor syndrome, boredom, a lack of purpose, direction, and a clear path to mastery find their  direction, purpose, and drive how to master software design and architecture through a process I call "Software Essentialism". software designer, writer, philosopher, and software developer, writer, and musician. He frequently publishes
//     articles about Domain-Driven Design, software design and Advanced TypeScript & Node.js best practices for large-scale applications.